.discount-value {
  color: #29b866;
  font-weight: 600;
}

.standard-price {
  color: #888888;
  text-decoration: line-through;
}

.discount-price {
  color: #29b866;
  font-weight: 600;
  font-size: larger;
  white-space: nowrap;
}

.discount-icon {
  color: #29b866;
  font-weight: 600;
  text-align: center;
  font-size: larger;
  padding-bottom: 0.2rem;
}
