.pricing-row {
  padding-right: 3rem;
  padding-left: 3rem;
}

@media (max-width: 992px) {
  .pricing-row {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .pricing-row {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}

@media (max-width: 576px) {
  .pricing-row {
    padding-right: 0;
    padding-left: 0;
  }
}
