.icon-square {
  width: 3rem;
  height: 3rem;
  border-radius: 0.75rem;
}

.text-bg-business {
  color: #8e6f00 !important;
  background-color: #fce865;
}
