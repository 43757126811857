@media (max-width: 375px) {
  .img-container {
    max-height: 200px;
  }
}

@media (max-width: 399px) {
  .appStoreBadge {
    width: 136px;
    height: 45.18px;
  }
}

@media (min-width: 375px) and (max-width: 768px) {
  .img-container {
    max-height: 300px;
  }
}

@media (min-width: 768px) {
  .img-container {
    max-height: 400px;
  }
}
