.plan-price {
  font-size: 2.4rem;
  color: #29b866;
  font-weight: 400;
}

.plan-price span {
  font-size: 2rem;
  font-weight: 400;
  margin-right: 0.8rem;
}

.plan-price span.reduced {
  font-size: 1.8rem;
  font-weight: 300;
  margin-right: 0.8rem;
}
