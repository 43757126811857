/* make the customizations */
// $theme-colors: (
//   "primary": teal,
// );

// $input-focus-box-shadow: 0 0 0 0.25rem rgba(53, 253, 13, 0.25);

$font-family-base: 'Lato', 'Arial', sans-serif;

@import '~bootstrap/scss/bootstrap';
